import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

import { IChallengeSettings } from './Settings/challengeSettings/challengeSettings.types';
import { SettingsKeysAbbreviation } from './Settings/constants/settingsKeysAbbreviation';
import { TextAlignment } from '../ChallengesPage/Settings/challengeSettings/challengeSettings.types';

export default createSettingsParams<IChallengeSettings>({
  displayRewards: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayGroup: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayInstructors: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  /**
   * sidebar layout
   */
  sidebarLayoutTextAlignment: {
    getDefaultValue({ isRTL }) {
      return isRTL ? TextAlignment.Right : TextAlignment.Left;
    },
  },
  sidebarLayoutSpace: {
    type: SettingsParamType.Number,
    inheritDesktop: false,
    getDefaultValue() {
      return 0;
    },
  },
  /**
   * participant page sidebar layout
   */
  sidebarLayoutDisplayChallengeDate: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeDuration: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeSteps: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeParticipants: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  displayChallengeDuration: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeSteps: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeParticipants: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  displayOneApp: {
    getDefaultValue() {
      return true;
    },
  },
  socialSharingIsEnabled: {
    getDefaultValue() {
      return true;
    },
  },
  joinViaOneAppForParticipantIsEnabled: {
    getDefaultValue() {
      return false;
    },
  },
  completeButtonTxt: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.page.steps.complete');
    },
  },
  prevButtonTxt: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.page.prev-step');
    },
  },
  nextButtonTxt: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.page.next-step');
    },
  },
  undoButtonTxt: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('live-site.step.undo');
    },
  },
  overviewTabDisplay: {
    getDefaultValue() {
      return true;
    },
  },
  [SettingsKeysAbbreviation.visitorPageInstructorTitle]: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('settings.visitor-page.instructor-default-title');
    },
  },
  programTitleTag: {
    getDefaultValue() {
      return 'h1';
    },
  },
});
